import { io } from 'socket.io-client';
import Vue from 'vue'
import { getCookie } from 'bh-mod'

// Initialize socket connection for app and set it on Vue prototype for global access
export const initSocket = () => {
	const token = getCookie('__bhjt');
	const instance = getCookie('instance');

	Vue.prototype.$socket = io(`https://api.bildhive.${Vue.prototype.$tld}/app`, {
		transports: ['websocket'],
		auth: {
			token: token
		},
		query: {
			instance: instance
		}
	});

	listenToSocketMessages();
}

// Listen to socket messages and log them
function listenToSocketMessages() {
	const socket = Vue.prototype.$socket;
	if (!socket) return;

	socket.on('connect', () => {
		console.log('Socket **** Connected to socket');
	});

	socket.on('connect_error', (error) => {
		console.error('Socket **** Connection error:', error);
	});

	socket.on('disconnect', (reason) => {
		console.warn('Socket **** Disconnected from server:', reason);

		if (reason === 'ping timeout' || reason === 'transport close') {
			console.warn('Attempting to reconnect...');
		}
	});

	socket.on('reconnect_attempt', (attempt) => {
		console.warn(`Socket **** Reconnect attempt: ${attempt}`);
	});

	socket.on('reconnect_failed', () => {
		console.error('Socket **** Reconnect failed after multiple attempts');
	});
}
